import React from "react";
import { generatePath } from "react-router-dom";
import { get, minBy } from "lodash";

import { STORE_PRODUCT_PAGE } from "settings/constant";
import SectionGrid from "components/SectionGrid/SectionGrid";
import Box from "components/UI/Box/Box";
import LinkTo from "components/UI/LinkTo/LinkTo";
import Text from "components/UI/Text/Text";
import { responsive } from "../utils";
import { StoreProductLoader } from "components/UI/ContentLoader/ContentLoader";
import { useBaseData } from "context/BaseDataProvider";
import { useAuth } from "context/AuthProvider";
import useDataApi from "library/hooks/useDataApi";
import ProductPrice from "components/ProductCard/ProductPrice";

const ProductGrid = ({ section }) => {
  const { getAssetUrl } = useBaseData();
  const { loggedIn } = useAuth();
  const configuration = get(section, "configuration", {});
  const components = get(section, "components", {});

  const url = `${process.env.REACT_APP_API_URL}/search/store-product`;
  const { data: products = [] } = useDataApi(
    `${url}?productId=${components.map((item) => item.productId)}`,
    components.length || 20
  );

  return (
    <SectionGrid
      data={section.components.map((item, index) => ({ ...item, id: index }))}
      limit={section.components.length}
      columnWidth={responsive(get(configuration, "columnWidth"), [0.5, 0.2])}
      placeholder={<StoreProductLoader />}
      renderComponent={(component) => {
        const product = products.find(
          (item) => item.id === component.productId
        );
        if (!product) {
          return <StoreProductLoader />;
        }
        const variant = minBy(product.variants, "sellingPrice");
        const linkTo = generatePath(STORE_PRODUCT_PAGE, {
          productId: component.productId,
        });

        return (
          <LinkTo to={linkTo}>
            <Box
              flexBox
              flexDirection="column"
              justifyContent="center"
              p={responsive(get(configuration, "padding"), 0)}
            >
              <Box
                style={{ position: "relative", overflow: "hidden" }}
                borderRadius={responsive(get(configuration, "cardRadius"), 20)}
              >
                <img
                  src={getAssetUrl(get(product, "photos.0.path"))}
                  alt={product.productName}
                  style={{
                    width: "100%",
                    height: "auto",
                    objectFit: "fill",
                    position: "relative",
                    aspectRatio: "3/4",
                  }}
                />
                <Box
                  flexBox
                  flexDirection="column"
                  alignItems="center"
                  bg="#fff"
                  style={{
                    padding: 4,
                    textAlign: 'center',
                    width: `calc(100% - 20px)`,
                  }}
                  borderRadius={responsive(
                    get(configuration, "cardRadius"),
                    20
                  )}
                >
                  <Text fontWeight={700} content={product.productName} />
                  <ProductPrice isLoggedIn={loggedIn} variant={variant} />
                </Box>
              </Box>
            </Box>
          </LinkTo>
        );
      }}
    />
  );
};

export default ProductGrid;
