import React from "react";

const useWindowSize = () => {
  const isClient = typeof window === "object";

  const getSize = React.useCallback(
    () => ({
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    }),
    [isClient]
  );

  const [size, setSize] = React.useState(getSize);

  React.useEffect(() => {
    if (!isClient) {
      return false;
    }
    const onHandleResize = () => {
      setSize(getSize);
    };
    window.addEventListener("resize", onHandleResize);
    return () => window.removeEventListener("resize", onHandleResize);
  }, [getSize, isClient]);

  const getBreakpoint = (width) => {
    if (width < 576) {
      return { index: 0, value: "xs" };
    }
    if (width < 768) {
      return { index: 1, value: "sm" };
    }
    if (width < 992) {
      return { index: 2, value: "md" };
    }
    if (width < 1200) {
      return { index: 3, value: "lg" };
    }
    if (width < 1600) {
      return { index: 4, value: "xl" };
    }
    return { index: 5, value: "xxl" };
  };

  return { ...size, breakpoint: getBreakpoint(size.width) };
};

export default useWindowSize;
